import React, { Component, Fragment, useState } from 'react';
import * as d3 from 'd3';
import { Button,Offcanvas } from 'react-bootstrap';
import {SwatchesPicker} from 'react-color';

import './Kscope.scss';


class Home extends Component {

    state = {
        addFlag: true,
        solidToggle: true,
        selectedColor: 'maroon',
        rotNum: 0,
        addType: 'circle',
        sizeVar: 50,
        rFlag: true,
        showColorPicker: false
    }

    // Defines function for re-adding kaleidoscope
    kaleidoGen = (g) =>{
        for(let x=0; x<=2; x++){
            for(let i=1; i<=4; i++){
                
                if(x === 0 && i === 1){
                    console.log('no')
                }else{
                    d3.select(g).clone(true)
                        .attr('transform',()=> {
                            if(i%4 === 0){
                                return 'translate(500 500) rotate('+(x*60)+') scale(-1 -1)';
                            }else if(i%3 === 0){
                                return 'translate(500 500) rotate('+(x*60)+') scale(1 -1)';
                            }else if(i%2 === 0){
                                return 'translate(500 500) rotate('+(x*60)+') scale(-1 1)';
                            }else{
                                return 'translate(500 500) rotate('+(x*60)+') scale(1 1)';
                            }
                        })
                        .attr('class','clone kaleido-group');
                }
            }
        }
    }
    
    // get cursor position in main svg
    cursorPoint = (evt) => {
        var svg = document.getElementById("main-svg");
        // var svg = evt.target;
        var pt = svg.createSVGPoint();
        pt.x = evt.clientX; pt.y = evt.clientY;
        return pt.matrixTransform(svg.getScreenCTM().inverse());
    }

    arcGenerator= () => {
        let x =  d3.arc();
        return x;
    }
    // Generate the path string
    pathData = () => {
        let x = d3.arc()({
            startAngle: 0,
            endAngle: Math.PI/6,
            innerRadius: 0,
            outerRadius: 500
        });
        return x
    }

    toggleReflection = () => {
        let addFlag = this.state.addFlag
        if(addFlag){

            d3.selectAll('.shapes').attr('transform','rotate(0)');
            
            d3.select('#pie-slice-clip').style('display', 'none');
            d3.selectAll('.clone')
            .each((dx,ix,nx)=>{
                d3.select(nx[ix]).remove();
            })
            clearInterval(this.rotater);

        }else{
        
            d3.select('#pie-slice-clip').style('display', 'inline');
            this.kaleidoGen('.main-slice');

        }

        this.setState({
            addFlag: !addFlag,
            rotNum: 0
        })
    }

    toggleFill = () => {
        
        let solidToggle = this.state.solidToggle;

        this.setState({
            solidToggle: !solidToggle
        })

    }

    shapeHolderChange = () => {
        d3.select("#shape-add-preview").selectAll("*").remove();
        
        if(this.state.addType === 'circle'){

            d3.select('#shape-add-preview')
                // .attr('transform','translate('+mouseX+' '+mouseY+')')
                .attr('transform-origin', '500 500')
                .append('circle')
                .attr('cx', (0))
                .attr('cy', (0))
                .attr('r', this.state.sizeVar)
                .style('fill', () => this.state.solidToggle ? this.state.selectedColor : 'transparent')
                .style('stroke',this.state.selectedColor)
                .attr('stroke-width', 15)

        }else if(this.state.addType === 'rect'){

            d3.select('#shape-add-preview')
                // .attr('transform','translate('+mouseX+' '+mouseY+')')
                .attr('transform-origin', '500 500')
                .append('rect')
                .attr('x', -(this.state.sizeVar/2))
                .attr('y', -(this.state.sizeVar/2))
                .attr('height', this.state.sizeVar)
                .attr('width', this.state.sizeVar)
                // .style('fill', this.state.selectedColor)
                .style('fill', () => this.state.solidToggle ? this.state.selectedColor : 'transparent')
                .style('stroke',this.state.selectedColor)
                .attr('stroke-width', 15)

        }
        
    }

    // Click function for adding new shapes
    mainSvgClickEvent = (event) =>{
        var loc = this.cursorPoint(event);

        d3.selectAll('.shapes').each((dx,ix,nx)=>{

            if(this.state.addType === 'circle'){

                // console.log(event)
                d3.select(nx[ix]).append('circle')
                .attr('cx', () => {
                    // if(this.state.addFlag){
                    //     return d3.pointer(nx[0])[0]
                    // }else{
                    //     // console.log(d3.pointer(nx[ix])[0])
                    //     return d3.pointer(nx[ix])[0]
                    // }
                    // return event.offsetX
                    loc = this.cursorPoint(event);
                    return loc.x - 500
                })
                .attr('cy', () => {
                    // if(this.state.addFlag){
                    //     return d3.pointer(nx[0])[1]


                    // }else{
                    //     return d3.pointer(nx[ix])[1]
                    // }
                    // return event.offsetY
                    return loc.y - 500
                })
                .attr('r', this.state.sizeVar)
                .style('fill', 'transparent')
                .style('fill', () => this.state.solidToggle ? this.state.selectedColor : 'transparent')
                .style('stroke',this.state.selectedColor)
                .attr('stroke-width', 15)
                

            }else if(this.state.addType === 'rect'){

                d3.select(nx[ix]).append('rect')
                // .attr('x', () => {
                //     if(this.state.addFlag){
                //         return d3.pointer(nx[0])[0]-(this.state.sizeVar/2)


                //     }else{
                //         return d3.pointer(nx[ix])[0]-(this.state.sizeVar/2)
                //     }
                // })
                // .attr('y', () => {
                //     if(this.state.addFlag){
                //         return d3.pointer(nx[0])[1]-(this.state.sizeVar/2)


                //     }else{
                //         return d3.pointer(nx[ix])[1]-(this.state.sizeVar/2)
                //     }
                // })
                .attr('x', loc.x - this.state.sizeVar/2 - 500)
                .attr('y', loc.y - this.state.sizeVar/2 - 500)
                // .attr('transform-origin', (d3.pointer(nx[0])[0])+' '+(d3.pointer(nx[0])[1]))
                .attr('transform-origin', (loc.x)+' '+(loc.y))
                .attr('height', this.state.sizeVar)
                .attr('width', this.state.sizeVar)
                .style('transform', 'rotate('+(-this.state.rotNum)+'deg)')
                .style('fill', () => this.state.solidToggle ? this.state.selectedColor : 'transparent')
                .style('stroke',this.state.selectedColor)
                .attr('stroke-width', 15)

            }

        })
    }

    mainSvgMouseEnterEvent = (event) => {

        var loc = this.cursorPoint(event);
    
        d3.select("#shape-add-preview")
            .style('display', 'inline')
            .attr('transform','translate('+loc.x+' '+loc.y+')');

        this.shapeHolderChange();
    }

    mainSvgMouseLeaveEvent = () => {
        d3.select("#shape-add-preview").style('display', 'none');
    }

    mainSvgMouseMoveEvent = (event) => {     
        var loc = this.cursorPoint(event);

        d3.select("#shape-add-preview")
        .attr('transform','translate('+loc.x+' '+loc.y+')')
    }


    // // Changes clip path / mask rotation
    // d3.select('#rotation-toggle').on("change", function(d,i,n) {

    //     setState({
    //         rotNum: d3.select(n[i]).property('value')
    //     })
        
    //     d3.selectAll('.shapes').attr('transform','rotate('+(this.state.rotNum)+')');

    // })

    shapeToggleFunc = () => {
        // if(this.state.addType === 'circle'){
        //     this.setState({
        //         addType: 'rect'
        //     })
        // }else{
        //     this.setState({
        //         addType: 'circle'
        //     })
        // }

        let addType = this.state.addType;

        addType === 'circle' ? addType = 'rect' : addType = 'circle';

        this.setState({
            addType: addType
        })
        // this.shapeHolderChange();
    }

    rotater = () => {

    }

    startRotate = ()=>{

        let rFlag = this.state.rFlag

        this.setState({
            rFlag: !rFlag
        })

        if(this.state.rFlag){
            this.rotater = setInterval(()=>{

                let rotNum = this.state.rotNum

                this.setState({
                    rotNum: rotNum+1
                })

                d3.selectAll('.shapes').attr('transform','rotate('+(this.state.rotNum)+')');


            },50);
        }else{
            clearInterval(this.rotater);
        }

        
    }

    showColorPicker = () => {
        let showColorPicker = this.state.showColorPicker
        this.setState({
            showColorPicker: !showColorPicker
        })
    }

    changeColor = (color) => {
        console.log(color.rgb);
        let colRGB = color.rgb
        let selectedColorRGBA = 'rgba(' + 
            colRGB.r + ',' +
            colRGB.g + ',' +
            colRGB.b + ',' +
            colRGB.a + ')';

        console.log(selectedColorRGBA)
        this.setState({
            selectedColor: selectedColorRGBA
        })
    }


    increaseSize = () => {
        let sizeVar = this.state.sizeVar + 3;
        this.setState({
            sizeVar: sizeVar
        })
    }

    decreaseSize = () => {
        let sizeVar = this.state.sizeVar - 3;
        this.setState({
            sizeVar: sizeVar
        })
    }

    clearAllShapes = () => {
        const shapeElements = Array.from(document.getElementsByClassName("shapes"));

        shapeElements.map(element=>{
            element.innerHTML = "";
        })
    }


    componentDidMount () {

        // Defines mask for highlighting active kaleidoscope section
        d3.select('#main-svg')
            .append("mask")       
            .attr("id", "pie-slice-mask")
            .append('circle')
            .attr('cx',500)
            .attr('cy',500)
            .attr('r',500)
            .attr('fill','white')
        d3.select('mask')
            .append('path')
            .attr('transform','translate(500 500)')
            .attr('d', ()=>{
                let x = this.pathData();
                return x;
            })
            .attr('fill','black')

        // Defines clipping section for acrtive kaleidoscope section
        d3.select('#main-svg')
            .append("clipPath")       
            .attr("id", "pie-slice-clip")
            .append('path')
            .attr('d', ()=>{
                let x = this.pathData()
                return x
            });


        let mSvg = d3.select('#total-group')
            
        d3.select('#main-svg')
            .append("rect")       
            .attr("id", "highlight-path")
            .style('pointer-events','none')
            .style('opacity',0.7)
            .attr('mask','url("#pie-slice-mask")')
            .attr('fill','#555555')
            .attr('width',1000)
            .attr('height',1000)
            .style('display','none')
            
        // Initial kaleidoscope setup
        for(let x=0; x<=2; x++){

            for(let i=1; i<=4; i++){

                let g = mSvg.append('g')
                    // .attr('transform-origin', '0 500')
                    // .attr('transform','translate(500 500)')
                    .attr('transform',()=> {
                        if(i%4 === 0){
                            return 'translate(500 500) rotate('+(x*60)+') scale(-1 -1)';
                        }else if(i%3 === 0){
                            return 'translate(500 500) rotate('+(x*60)+') scale(1 -1)';
                        }else if(i%2 === 0){
                            return 'translate(500 500) rotate('+(x*60)+') scale(-1 1)';
                        }else{
                            return 'translate(500 500) rotate('+(x*60)+') scale(1 1)';
                        }
                    })
                    .attr('class', ()=>{
                        if(x === 0 && i === 1){
                            return 'main-slice kaleido-group'
                        }else{
                            return 'clone kaleido-group'
                        }
                    })
                    .attr("clip-path","url(#pie-slice-clip)").append('g').attr('class', 'shapes')


                g
                    .append("rect")
                    .attr("x",0)
                    .attr("y",-500)
                    // .attr("clip-path","url(#pie-slice-clip)")
                    .attr("fill","SteelBlue")
                    .attr("height",300)
                    .attr("width",300)

                g.append("circle")
                    .attr("cx",0)
                    .attr("cy",-270)
                    .attr('r',200)
                    // .attr("clip-path","url(#pie-slice-clip)")
                    .attr("fill","teal")

                g.append("circle")
                    .attr("cx",0)
                    .attr("cy",0)
                    .attr('r',100)
                    // .attr("clip-path","url(#pie-slice-clip)")
                    .attr("fill","pink")

                g.append("circle")
                    .attr("cx",80)
                    .attr("cy",-400)
                    .attr('r',80)
                    // .attr("clip-path","url(#pie-slice-clip)")
                    .attr("fill","cyan")
                
                g
                .append("rect")
                .attr("x",0)
                .attr("y",-300)
                // .attr("clip-path","url(#pie-slice-clip)")
                .attr("fill","SteelBlue")
                .attr("height",100)
                .attr("width",200)

            }

        }
    }

    

    render() {
        // d3.select('#shape-add-preview').style('pointer-events','none').style('opacity', 0.7)
    
            // Click function for hiding inactive kaleidoscope sections
            // d3.select('#remove-btn').on('click', (d,i,n)=>{

            //     d3.select(n[i]).property('disabled', true);
            //     d3.select('#add-btn').property('disabled', false);
            //     d3.select('#pie-slice-clip').style('display', 'none');
            //     this.state.addFlag = false;

            //     // d3.select('#main-svg').transition().duration(1000).attr('viewBox', '500 0 500 500')

            //     d3.selectAll('.clone')
            //     .each((dx,ix,nx)=>{
            //         d3.select(nx[ix]).remove();
            //     })

            // })

            // d3.select('#add-btn').on('click', (d,i,n)=>{

            //     d3.select(n[i]).property('disabled', true);
            //     d3.select('#remove-btn').property('disabled', false);
            //     d3.select('#pie-slice-clip').style('display', 'inline');
            //     this.state.addFlag = true;

            //     // d3.select('#main-svg').transition().duration(1000).attr('viewBox', '0 0 1000 1000')

            //     this.kaleidoGen('.main-slice');
            // })

            // Declare variable for shape type to add new pieces
            // let solidToggle = true;


            // Function for when mouse is over SVG

            // d3.select('#main-svg').on("mouseleave", function(d,i,n) {
            //     d3.select("#shape-add-preview").style('display', 'none');
            // })



            // Toggles vibility of active section highlight
            // d3.select('#highlight-toggle').on("change", function(d,i,n) {

            //     if(!d3.select(n[i]).property('checked')){

            //         d3.select('#highlight-path')
            //             .style('display','none')
            //     }else{
            //         d3.select('#highlight-path')
            //             .style('display','inline')
            //     }
                

            // })


            let rotater;







            let highlightFlag = false;

            // document.addEventListener('keydown', function(event) {
            //     console.log(event.key)

            //     if(event.key === 't'){
                    
            //         this.toggleReflection();

            //     }else if(event.key === 'h'){

            //         highlightFlag = !highlightFlag;

            //         if(highlightFlag){
            //             d3.select('#highlight-path')
            //                 .style('display','inline')
            //         }else{
            //             d3.select('#highlight-path')
            //                 .style('display','none')
            //         }

            //     }else if(event.key === 'c'){
            //         this.state.addType = 'circle'
            //     }else if(event.key === 's'){
            //         this.state.addType = 'rect'
            //     }else if(event.key === 'x'){
            //         this.state.solidToggle = !this.state.solidToggle;
            //     }else if(event.key === 'ArrowUp'){
            //         if(this.state.sizeVar <500){
            //             setState({
            //                 sizeVar: this.state.sizeVar + 3
            //             })
                        
            //         }
            //     }else if(event.key === 'ArrowDown'){
            //         if(this.state.sizeVar >3){
            //             setState({
            //                 sizeVar: this.state.sizeVar - 3
            //             })
            //         }
            //     }else if(event.key === ' '){

            //         if(this.state.rFlag){
            //             startRotate();
            //         }else{
            //             this.setState({
            //                 rFlag: !this.state.rFlag
            //             })
            //             clearInterval(rotater);
            //         }
            //     }
            //     // if (event.code == 'KeyZ' && (event.ctrlKey || event.metaKey)) {
            //     //     alert('Undo!')
            //     // }

            //     d3.select("#shape-add-preview").selectAll("*").remove();

            //     this.shapeHolderChange;

            // });
            window.addEventListener('load', function () {

                


            })

        

      return (
        <Fragment>
             <div id="main-container" >
                <div style={{flex:1,height:"200px"}}>
                    <svg 
                        id="main-svg" 
                        width="100%" 
                        height="100%" 
                        viewBox="0 0 1000 1000" 
                        preserveAspectRatio="xMidyMid" 
                        onClick={this.mainSvgClickEvent}
                        onMouseEnter={this.mainSvgMouseEnterEvent}
                        onMouseLeave={this.mainSvgMouseLeaveEvent}
                        onMouseMove={this.mainSvgMouseMoveEvent}
                    >
                        <g id="total-group"></g>
                        <g id="shape-add-preview"></g>
                    </svg>
                </div>
                <div className='text-center'>
                    <Button
                        onClick={this.toggleReflection}
                    >
                        toggle reflection
                    </Button>
                    <Button
                        onClick={this.startRotate}
                    >
                        rotate
                    </Button>
                    <Button
                        onClick={this.shapeToggleFunc}
                    >
                        toggle shape
                    </Button>
                    <Button
                        onClick={this.toggleFill}
                    >
                        toggle fill
                    </Button>
                    <Button
                        onClick={this.increaseSize}
                    >
                        +
                    </Button>
                    <Button
                        onClick={this.decreaseSize}
                    >
                        -
                    </Button>

                    <Button variant="primary" onClick={this.showColorPicker} className="me-2">
                        choose color
                    </Button>
                    <Offcanvas show={this.state.showColorPicker} onHide={this.showColorPicker}>
                        <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Shape Color</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <SwatchesPicker
                                onChange={this.changeColor}
                            />
                        </Offcanvas.Body>
                    </Offcanvas>
                
                    <Button
                        onClick={this.clearAllShapes}
                        variant="danger"
                    >
                        CLEAR ALL
                    </Button>
                    
                </div>
            </div>
        </Fragment>
      );
    }
  }
  
  export default Home;