import React, { Fragment,Component } from 'react';

class TestItem extends Component {
    render() {
      return (
          <Fragment>
              <h2>I am a terrible component</h2>
              {this.props.someInput}
          </Fragment>
      );
    }
  }
  
  export default TestItem;