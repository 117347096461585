import React, { Component, Fragment } from 'react';

const headerStyle = {
    textAlign: 'center',
    margin: '50px'
}

class Home extends Component {
    render() {
      return (
        <Fragment>
            <h1 style={headerStyle}>G TEST</h1>
        </Fragment>
      );
    }
  }
  
  export default Home;