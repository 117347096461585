import React, { Component, Fragment } from 'react';
import TestItem from '../components/Test_component';

import { Col,Container,Row } from 'react-bootstrap';

const headerStyle = {
    color: 'blue',
    background: 'red',
    textAlign: 'center',
    margin: 0
}

class Home extends Component {
    render() {
      return (
        <Fragment>
          <div className=' w-100 h-100 d-flex' style={{flexDirection: 'column'}}>
            <h1 style={headerStyle}>W3Lc9ME HOMe</h1>
              {/* <TestItem
                  someInput = {
                      <h3>woooter</h3>
                  }
              /> */}
            <Container className='home-bg-container'>
              <Row>
                <Col className='text-center'>1 of 2</Col>
                <Col className='text-center'>2 of 2</Col>
              </Row>
            </Container>
          </div>
        </Fragment>
      );
    }
  }
  
  export default Home;