import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import './Custom.css';
import { Container,Navbar,Offcanvas,Nav,NavDropdown } from 'react-bootstrap';

class CustomNav extends Component {
  render() {
    return (
      <Fragment>
        {/* <Navbar
          brand="Burner Pages"
          email={this.props.email}
          className="indigo darken-4"
          right
        >
          <NavItem onClick={() => console.log('test click')}>
            <Link to="/auth/google" className="navlink">
              Google
            </Link>
          </NavItem>
          <NavItem onClick={() => console.log('test click')}>
            <Link to="/" className="navlink">
              Home
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/about" className="navlink">
              About
            </Link>
          </NavItem>
        </Navbar> */}
      <Navbar bg="light" expand={false}>
        <Container fluid>
          <Navbar.Brand href="#">George's Page</Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Offcanvas</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3 me-auto my-2 my-lg-0">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/k_scope">Kaleidoscope</Nav.Link>
                <Nav.Link href="/rainbow_drag">Rainbow Drag</Nav.Link>

                <NavDropdown title="Dropdown" id="offcanvasNavbarDropdown">
                  <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
        

      </Fragment>
    );
  }
}

export default CustomNav;