import React, { Component, Fragment } from 'react';
// import './step_syn.css';
// import { Link } from 'react-router-dom';

const headerStyle = {
    color: 'blue',
    background: 'red'
}



class Home extends Component {
      componentDidMount () {
        const script = document.createElement("script");
        script.src = "./step_syn.js";
        script.async = false;
        document.body.appendChild(script);
    }
    render() {
      return (
        <Fragment>
          <div id="main-controls" className="container my-3">
              <div className="row">
                  {/* <div className="col-12">
                      <a href="./index.html" className="nav-link">back</a>
                  </div> */}
                  <div className="col-12">
                      <div id="note-rect-context-menu">
                          {/* <button value="16n">1/16</button><br>
                          <button value="8n">1/8</button><br>
                          <button value="4n">1/4 </button><br>
                          <button value="2n">1/2</button><br>
                          <button value="1n">Whole</button> */}
                          <button value="plus">+</button>
                          <button value="minus">-</button>

                      </div>
                      <input id="bpm-switch" min="30" max="240" step="10" defaultValue={"120"} type="number"/>
                      <button id="add-more-chunks" type="button">ADD MORE NOTES</button>
                      <button id="tone1">play</button>
                      <button id="tone2">stop</button>
                      <button id="record-btn">record</button>
                      <a id="download-link" href="" download>download</a>
                  </div>
              </div>
          </div>

          <div id="synth-holder" className="container">
              <div id="empty-main-synth" className="row synth-row" style={{display: 'none'}}>
                  <div className="col-6">
                      <h2 style={{color: 'white'}} className="scale-title"></h2>
                  </div>
                  <div className="col-6">
                      <select className="key-select" readOnly defaultValue={'C'}>
                          <option value="C" >C</option>
                          <option value="C#">C#</option>
                          <option value="D">D</option>
                          <option value="E">E</option>
                          <option value="Eb">Eb</option>
                          <option value="F">F</option>
                          <option value="F#">F#</option>
                          <option value="G">G</option>
                          <option value="G#">G#</option>
                          <option value="A">A</option>
                          <option value="Bb">Bb</option>
                          <option value="B">B</option>
                      </select>
                  
                      <select className="octave-select" readOnly defaultValue={4}>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4" >4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          {/* <option value="9">9</option> */}
                      </select>
                  
                      <select className="osc-type-select" defaultValue={'sine'}>
                          <option value="sine">sine</option>
                          <option value="sawtooth">sawtooth</option>
                          <option value="triangle">triangle</option>
                          <option value="square">square</option>
                          {/* <option value="9">9</option> */}
                      </select>

                  </div>

                  <div className="col-12">
                      <div style={{width: '100%', overflowX: 'auto'}}>
                          <div style={{width: '100%', height: '500px'}} >
                              <svg width="1000" height="1000" viewBox="0 0 1000 1000" preserveAspectRatio="none"></svg>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

          <div id="sequencer-add-contols" className="container my-2">
              <div className="row">
                  <div className="col-12" style={{textAlign: 'center'}}>
                      <select id="scale-select" readOnly defaultValue={'X'}></select>            
                      <button id="sequence-gen-btn" type="button">ADD STEP SEQUENCER</button>
                  </div>
              </div>
          </div>
        </Fragment>
      );
    }
  }
  
  export default Home;