import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import Home from "./pages/Home"; //???
import Testing from "./pages/Testing";
import Home from "./pages/Home";
// import UnderConstruction from "./pages/UnderConstruction";
import D3playground from "./pages/d3_projects/D3playground";
import D3stepSyn from "./pages/d3_projects/step_syn_page";
import D3kaleidoscope from "./pages/kaleidoscope/k_page";
import RainbowDrag from "./pages/rainbow_drag";
import OwnNavBar from "./OwnNavBar";

import './App.scss';

class App extends Component {
  
  render() {
    return (
      <Router>
        <Fragment>
          <div style={{flexDirection:"column",display:"flex",height:"100vh",width:"100vw"}}>
            <OwnNavBar />
            <div style={{flex: 1,background: "black", color: "green",height:"100px"}}>

              <Switch>
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/" component={UnderConstruction} /> */}
                <Route exact path="/testing" component={Testing} />
                {/* <Route exact path="/d3" component={D3playground} /> */}
                <Route exact path="/step_syn" component={D3stepSyn} />
                <Route exact path="/k_scope" component={D3kaleidoscope} />
                <Route exact path="/rainbow_drag" component={RainbowDrag} />
              
                {/* <Route component={Error} /> */}
              </Switch>
            </div>
          </div>
          
        </Fragment>
      </Router>
    );
  }
}

export default App;