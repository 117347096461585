import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

const headerStyle = {
    color: 'blue',
    background: 'red'
}

class Home extends Component {
    render() {
      return (
        <Fragment>
            <div id="nav-container">
              <Link to="/d3/step_syn" style={{ color: 'red' }}>
                blah
              </Link>
                {/* <a href='{this.props.url}' class="nav-link">Single File</a> */}
                {/* <br>
                <a href="./full_screen.html" class="nav-link">Full Screen</a>
                <br>
                <a href="./hypnotize.html" class="nav-link">Hypnotize (WARNING: may hurt brain)</a>
                <br>
                <a href="./music_visualizer1.html" class="nav-link">Music Visualizer I</a>
                <br>
                <a href="./music_visualizer2.html" class="nav-link">Music Visualizer II</a>
                <br>
                <a href="./music_visualizer3.html" class="nav-link">Music Visualizer III</a>
                <br>
                <a href="./box_shift.html" class="nav-link">Box Shift</a>
                <br>
                <a href="./kaleidoscope.html" class="nav-link">Digital Kaleidoscope</a>
                <br>
                <a href="./syn_builder.html" class="nav-link" style="background-color: rgb(99, 204, 169);">Step Sequencer</a> */}
            </div>
        </Fragment>
      );
    }
  }
  
  export default Home;